var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_settlement") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "formModel",
                      attrs: {
                        model: _vm.filters,
                        layout: "horizontal",
                        "label-col": { span: 8 },
                        "wrapper-col": { span: 14 },
                        "label-align": "left"
                      }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_settlement_date"),
                                    prop: "settlementDate"
                                  }
                                },
                                [
                                  _c("a-range-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      placeholder: [
                                        _vm.$t("lbl_start_date"),
                                        _vm.$t("lbl_end_date")
                                      ],
                                      "data-testid": "settlement-date"
                                    },
                                    model: {
                                      value: _vm.filters.settlementDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "settlementDate",
                                          $$v
                                        )
                                      },
                                      expression: "filters.settlementDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_ujo_number"),
                                    prop: "ujoNumber"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.search-by-text",
                                        {
                                          text: _vm.$t("lbl_ujo_number")
                                        }
                                      ),
                                      "data-testid": "settlement-ujo-number"
                                    },
                                    model: {
                                      value: _vm.filters.ujoNumber,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "ujoNumber", $$v)
                                      },
                                      expression: "filters.ujoNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_shipment_id"),
                                    prop: "shipmentId"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.search-by-text",
                                        {
                                          text: _vm.$t("lbl_shipment_id")
                                        }
                                      ),
                                      "data-testid": "settlement-shipment-id"
                                    },
                                    model: {
                                      value: _vm.filters.shipmentId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "shipmentId", $$v)
                                      },
                                      expression: "filters.shipmentId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_customer"),
                                    prop: "customer"
                                  }
                                },
                                [
                                  _c("SelectCustomer", {
                                    attrs: {
                                      "data-testid": "settlement-customer",
                                      "label-in-value": ""
                                    },
                                    model: {
                                      value: _vm.filters.customer,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "customer", $$v)
                                      },
                                      expression: "filters.customer"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_unit_code"),
                                    prop: "unitCode"
                                  }
                                },
                                [
                                  _c("SelectTruck", {
                                    attrs: {
                                      "data-testid": "settlement-unit-code"
                                    },
                                    on: { "update:meta": _vm.onChangeUnitCode },
                                    model: {
                                      value: _vm.filters.unitCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "unitCode", $$v)
                                      },
                                      expression: "filters.unitCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_status"),
                                    prop: "status"
                                  }
                                },
                                [
                                  _c("SelectTruckingSettlementStatus", {
                                    attrs: {
                                      "data-testid": "settlement-status"
                                    },
                                    model: {
                                      value: _vm.filters.status,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "status", $$v)
                                      },
                                      expression: "filters.status"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.download || _vm.loading.find,
                            "data-testid": "settlement-reset"
                          },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.download || _vm.loading.find,
                            "data-testid": "settlement-find"
                          },
                          on: { click: _vm.handleFind }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      dataSource: _vm.settlements.data,
                      columns: _vm.settlementTableColumns,
                      "row-key": "id",
                      loading: _vm.loading.find,
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.settlements.totalElements
                          })
                        },
                        total: _vm.settlements.totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        defaultPageSize: _vm.pagination.limit
                      },
                      scroll: { y: 600, x: 1000 },
                      "row-selection": {
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectedTableRowChange,
                        getCheckboxProps: _vm.getCheckboxProps
                      },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "settlement-table"
                    },
                    on: { change: _vm.onChangeTable },
                    scopedSlots: _vm._u([
                      {
                        key: "ujoNumber",
                        fn: function(text, record) {
                          return [
                            record.ujoNumber
                              ? _c(
                                  "RouterLink",
                                  {
                                    attrs: {
                                      to: {
                                        name: "trucking.settlement.detail",
                                        params: { id: record.id }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(text) + " ")]
                                )
                              : _c("span", [_vm._v(_vm._s("-"))])
                          ]
                        }
                      },
                      {
                        key: "journalNumber",
                        fn: function(text, record) {
                          return [
                            record.journalNumber && record.journalId
                              ? _c(
                                  "RouterLink",
                                  {
                                    attrs: {
                                      to: {
                                        name: "generaljournal.detail",
                                        params: {
                                          id: record.journalId
                                        }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(text) + " ")]
                                )
                              : _c("span", [_vm._v(_vm._s("-"))])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _vm.$can("approve", "trucking-invoice-ar")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                loading:
                                  _vm.loading.download || _vm.loading.find,
                                "data-testid": "settlement-posting"
                              },
                              on: { click: _vm.handlePosting }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_posting")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.download || _vm.loading.find,
                            "data-testid": "settlement-download"
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PostingModal", {
        attrs: {
          visible: _vm.modal.visible,
          truckingSettlementIds: _vm.modal.truckingSettlementIds
        },
        on: { close: _vm.handleModalClose, posted: _vm.handleAfterPost }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }