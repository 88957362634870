




import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectTruckingSettlementStatus extends Vue {
  @Prop({ required: false, default: "", type: String })
  value!: string;

  options: Array<Option> = [
    {
      key: 0,
      label: this.$t("lbl_all").toString(),
      value: "",
    },
    {
      key: 1,
      label: this.$t("lbl_need_approval").toString(),
      value: "NEED_APPROVAL",
    },
    {
      key: 2,
      label: this.$t("lbl_approved").toString(),
      value: "APPROVED",
    },
    {
      key: 3,
      label: this.$t("lbl_rejected").toString(),
      value: "REJECTED",
    },
    {
      key: 3,
      label: this.$t("lbl_posted").toString(),
      value: "POSTED",
    },
  ];

  onChange(e: string): void {
    this.$emit("input", e);
  }
}
