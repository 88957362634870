import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DownloadRequestParam } from "@/models/interface/download";

export class TruckingSettlementMapper {
  static paginationParamToParamDownload(
    req: RequestQueryParams,
    param: {
      companyName: string;
      ujoNumber: string;
      shipmentId: string;
      customerName: string;
      unitCode: string;
      status: string;
      startDate: string;
      endDate: string;
    }
  ): DownloadRequestParam {
    const params = [
      param.companyName,
      param.ujoNumber,
      param.shipmentId,
      param.customerName,
      param.unitCode,
      param.status,
      param.startDate,
      param.endDate,
    ];

    return {
      limit: req.limit,
      page: req.page,
      params: params.join(","),
      search: req.search,
      sorts: req.sorts,
    };
  }
}
