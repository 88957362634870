












































































































































































































import { SearchBuilder } from "@/builder";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectTruck from "@/components/custom/select/SelectTruck.vue";
import SelectTruckingSettlementStatus from "@/components/custom/select/SelectTruckingSettlementStatus.vue";
import { PostingModal } from "@/components/TruckingSettlement";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { APagination, useBlob, useDate, useTruckingSettlement } from "@/hooks";
import { TruckingSettlementMapper } from "@/mapper/TruckingSettlement.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_DD_MMM_YYYY,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { DownloadRequestParam } from "@/models/interface/download";
import { AssetResponseDto } from "@/models/interface/master-asset";
import {
  TruckingSettlementListFilterState,
  TruckingSettlementResponseDto,
} from "@/models/interface/trucking-settlement";
import { SorterProps } from "@/types";
import { FormModel } from "ant-design-vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    SelectTruckingSettlementStatus,
    SelectCustomer,
    SelectTruck,
    PostingModal,
  },
})
export default class TruckingSettlement extends MNotification {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  formModel!: FormModel;

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  settlements: Pagination<TruckingSettlementResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  settlementTableColumns = [
    {
      title: this.$t("lbl_settlement_date"),
      dataIndex: "settlementDate",
      key: "settlementDate",
      sorter: true,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_ujo_number"),
      dataIndex: "ujoNumber",
      key: "ujoNumber",
      sorter: true,
      scopedSlots: { customRender: "ujoNumber" },
    },
    {
      title: this.$t("lbl_shipment_id"),
      dataIndex: "shipmentId",
      key: "shipmentId",
      sorter: true,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_customer"),
      dataIndex: "customer",
      key: "customer",
      sorter: true,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      sorter: true,
      customRender: text => text || "-",
    },
    {
      title: this.$t("common.total-text", {
        text: this.$t("lbl_settlement"),
      }),
      dataIndex: "settlementTotal",
      key: "settlementTotal",
      sorter: true,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumber",
      key: "journalNumber",
      sorter: true,
      scopedSlots: { customRender: "journalNumber" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      customRender: text => text || "-",
    },
  ];

  filters: TruckingSettlementListFilterState = {
    settlementDate: [],
    status: "",
    ujoNumber: "",
    shipmentId: "",
    customer: undefined,
    unitCode: undefined,
  };

  selectedRowKeys: string[] = [];

  modal = {
    visible: false,
    truckingSettlementIds: [] as Array<string>,
    toggle(): void {
      this.visible = !this.visible;
    },
    reset(): void {
      this.visible = false;
      this.truckingSettlementIds = [];
    },
  };

  getCheckboxProps(record: TruckingSettlementResponseDto) {
    return {
      props: {
        disabled: record.status !== "Approved",
      },
    };
  }

  onSelectedTableRowChange(keys: string[]): void {
    this.selectedRowKeys = keys;
  }

  onChangeUnitCode(truck: AssetResponseDto | undefined): void {
    if (!truck) {
      return;
    }

    this.filters.unitCode = truck.unitCode;
  }

  buildQueryParams(
    filter: TruckingSettlementListFilterState
  ): RequestQueryParams {
    const { toStartDay, toEndDay } = useDate();

    const builder = new SearchBuilder();
    const queries: string[] = [];

    if (filter.ujoNumber) {
      queries.push(
        builder
          .push(["ujoNumber", filter.ujoNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (filter.status) {
      queries.push(builder.push(["status", filter.status]).build());
    }

    if (filter.settlementDate && filter.settlementDate.length > 0) {
      const [start, end] = filter.settlementDate;

      queries.push(
        builder
          .push(["settlementDate", toStartDay(start).format()], {
            het: true,
          })
          .and()
          .push(["settlementDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    if (filter.shipmentId) {
      queries.push(
        builder
          .push(["shipmentId", filter.shipmentId], { like: "both" })
          .build()
      );
    }

    if (filter.customer?.key) {
      queries.push(
        builder.push(["customer.secureId", filter.customer.key]).build()
      );
    }

    if (filter.unitCode) {
      queries.push(builder.push(["asset.unitCode", filter.unitCode]).build());
    }

    const params = new RequestQueryParams();
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.search = queries.join(builder.AND);
    params.sorts = "settlementDate:desc";

    return params;
  }

  fetchSettlements(params: RequestQueryParams): void {
    const { findListSettlements } = useTruckingSettlement();

    this.loading.find = true;
    findListSettlements(params)
      .then(res => {
        this.settlements = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  handleReset(): void {
    this.pagination.reset();
    this.formModel.resetFields();

    const params = this.buildQueryParams(this.filters);
    this.fetchSettlements(params);

    this.selectedRowKeys = [];
  }

  handleFind(): void {
    this.pagination.reset();
    const params = this.buildQueryParams(this.filters);

    this.fetchSettlements(params);
  }

  handleModalClose(): void {
    this.modal.reset();
  }

  handleAfterPost(): void {
    this.handleReset();
  }

  updateQueryFilter(): void {
    this.$router.replace({
      name: "trucking.settlement",
      query: {
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  getSortKey(source: string): string {
    if (source === "journalNumber") return "generalJournal.name";
    else if (source === "unitCode") return "asset.unitCode";
    return source;
  }

  onChangeTable(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;

    this.pagination.page = current;
    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams(this.filters);

    if (columnKey) {
      params.sorts = `${this.getSortKey(columnKey)}:${
        order === "ascend" ? "asc" : "desc"
      }`;
    }

    this.updateQueryFilter();
    this.fetchSettlements(params);
  }

  buildDownloadParams(params: RequestQueryParams): DownloadRequestParam {
    const { toStartDay, toEndDay } = useDate();

    const companyName = this.$store.state.authStore.authData.companyName;
    let startDate = "";
    let endDate = "";

    if (this.filters.settlementDate && this.filters.settlementDate.length > 0) {
      const [start, end] = this.filters.settlementDate;
      startDate = toStartDay(start).format(DATE_FORMAT_DD_MMM_YYYY);
      endDate = toEndDay(end).format(DATE_FORMAT_DD_MMM_YYYY);
    }

    return TruckingSettlementMapper.paginationParamToParamDownload(params, {
      companyName: companyName ? companyName : "All",
      customerName: this.filters.customer?.label
        ? this.filters.customer?.label
        : "All",
      endDate: endDate ? endDate : "All",
      shipmentId: this.filters.shipmentId ? this.filters.shipmentId : "All",
      startDate: startDate ? startDate : "All",
      status: this.filters.status ? this.filters.status : "All",
      ujoNumber: this.filters.ujoNumber ? this.filters.ujoNumber : "All",
      unitCode: this.filters.unitCode ? this.filters.unitCode : "All",
    });
  }

  handleDownload(): void {
    const { downloadListSettlements } = useTruckingSettlement();
    const { toDownload } = useBlob();

    const params = this.buildQueryParams(this.filters);
    params.limit = this.settlements.totalElements;
    params.page = 0;

    this.loading.download = true;
    downloadListSettlements(this.buildDownloadParams(params))
      .then(res => {
        toDownload(res, "trucking_settlementt.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  handlePosting(): void {
    if (this.selectedRowKeys.length === 0) {
      this.showNotifError("notif_must_select_settlement");
      return;
    }

    const selectedSettlements = this.settlements.data.filter(settlement =>
      this.selectedRowKeys.includes(settlement.id)
    );
    if (!this.isSettlementDateEqual(selectedSettlements)) {
      this.showNotifError("notif_settlement_date_must_be_equal");
      return;
    }

    this.modal.truckingSettlementIds = this.selectedRowKeys;
    this.modal.toggle();
  }

  isSettlementDateEqual(settlements: TruckingSettlementResponseDto[]): boolean {
    const date = dateFormat(settlements[0].settlementDate);

    for (const settlement of settlements) {
      if (dateFormat(settlement.settlementDate) !== date) {
        return false;
      }
    }

    return true;
  }

  mounted(): void {
    if (this.$refs.formModel) {
      this.formModel = this.$refs.formModel as FormModel;
    }

    const params = this.buildQueryParams(this.filters);
    this.fetchSettlements(params);
  }
}
