





















import SearchBuilder from "@/builder/SearchBuilder";
import { debounceProcess } from "@/helpers/debounce";
import useAsset from "@/hooks/useAsset";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SelectTruck extends Vue {
  @Prop({ type: String, default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AssetResponseDto>[] = [];

  loading = false;

  onChange(e: string): void {
    if (!e) {
      this.onSearch();
    }

    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(search?: string): void {
    const { findAllAsset, toOptionsNew } = useAsset();
    const { filterBy } = useAsset();

    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const query: string[] = [this.buildTruckQuery()];

    const filtered: string = filterBy({ unitCode: search });
    if (filtered) {
      query.push(filtered);
    }

    params.search = query.join(builder.AND);

    this.loading = true;
    findAllAsset(params)
      .then(res => {
        this.options = toOptionsNew(res.data);
      })
      .finally(() => (this.loading = false));
  }

  buildTruckQuery(): string {
    const builder = new SearchBuilder();
    const q: string[] = [];

    q.push(
      builder
        .push(["assetCategory.categoryId", "truk"], { like: "end" })
        .build()
    );

    return q.join(builder.AND);
  }

  findOption(id: string): AssetResponseDto | undefined {
    return this.options.find(e => id === e.key)?.meta;
  }

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.onSearch();
  }
}
